

import { createRoot } from 'react-dom/client';

import './style.scss';
import Style from './Style';
import Forms from './Forms';

// MailChimp Directory
document.addEventListener('DOMContentLoaded', () => {
	const allBlockDirectory = document.querySelectorAll('.wp-block-mcb-mailchimp');
	allBlockDirectory.forEach(directory => {
		const attributes = JSON.parse(directory?.dataset?.attributes);
		const dataInfo = JSON.parse(directory?.dataset?.mcbinfo || "{}");
		const { elements, content, config } = attributes;
		const { apiKey, audienceId, } = config;
		const { key, accessToken } = dataInfo;

		// if (!key && !apiKey || !audienceId) {
		// 	return <span> </span>
		// }

		if (!audienceId && !key) {
			return <span> </span>
		}

		createRoot(directory).render(<>
			<Style attributes={attributes} clientId={attributes.cId} />

			<Forms attributes={attributes} apiKey={key} accessToken={accessToken} >
				{(elements.title && content.title.length > 0) && <h2 dangerouslySetInnerHTML={{ __html: content?.title }} />}

				{(elements.desc && content.desc.length > 0) && <p dangerouslySetInnerHTML={{ __html: content?.desc }} />}
			</Forms>
		</>);

		directory?.removeAttribute('data-attributes');
	});
});
