

import { getBoxValue } from './utils/functions';

import { getBackgroundCSS, getBorderCSS, getColorsCSS, getTypoCSS } from '../../Components/utils/getCSS';
const Style = ({ attributes, clientId }) => {
	const { iconBG, icon, titleTypo, titleColor, descTypo, descColor, formBG, formPadding, formBorder, fieldTypo, fieldColors, fieldPadding, fieldBorder, fieldMargin, btnTypo, btnColors, btnHovColors, btnPadding, btnBorder, messageColors, messageTypo, themeWidth, themeAlign } = attributes;

	const formElement = `#mcbMailChimp-${clientId} .mcbMailChimp .form`;

	return <style dangerouslySetInnerHTML={{
		__html: `
		
		${getTypoCSS('', titleTypo)?.googleFontLink}
		${getTypoCSS('', descTypo)?.googleFontLink}
		${getTypoCSS('', fieldTypo)?.googleFontLink}
		${getTypoCSS('', btnTypo)?.googleFontLink}
		${getTypoCSS('', messageTypo)?.googleFontLink}
		${getTypoCSS(`${formElement} h2`, titleTypo)?.styles}
		${getTypoCSS(`${formElement} p`, descTypo)?.styles}
		${getTypoCSS(`${formElement} input::placeholder`, fieldTypo)?.styles}
		${getTypoCSS(`${formElement} input`, fieldTypo)?.styles}
		${getTypoCSS(`${formElement} .submitBtn button`, btnTypo)?.styles}
		${getTypoCSS(`${formElement} .noticeElement > p`, messageTypo)?.styles}

		#mcbMailChimp-${clientId} {
			justify-content:${themeAlign?.desktop}; 
		}

		#mcbMailChimp-${clientId} .mcbMailChimp{
			width: ${themeWidth?.desktop};
		}

		@media (max-width: 768px) { 
			#mcbMailChimp-${clientId}{
				justify-content:${themeAlign?.tablet};
			}
		}

		@media (max-width: 768px) {
			#mcbMailChimp-${clientId} .mcbMailChimp{
				width:${themeWidth?.tablet};
			}
		}

		@media (max-width: 576px) {
			#mcbMailChimp-${clientId}{
				justify-content:${themeAlign?.mobile};
			}
		}

		@media (max-width: 576px) {
			#mcbMailChimp-${clientId} .mcbMailChimp{
				width:${themeWidth?.mobile};
			}
		}	

		#mcbMailChimp-${clientId} .form1 .iconElement .icon {
			${getBackgroundCSS(iconBG)};
		}

		#mcbMailChimp-${clientId} .form1 .iconElement .icon::after {
			border-color:${icon?.color};
		}

		${formElement}{
			${getBackgroundCSS(formBG)};
			padding: ${getBoxValue(formPadding)};
			${getBorderCSS(formBorder)};
		}
		 
		${formElement} .noticeElement .success{
			color:${messageColors?.success};
		}

		${formElement} .noticeElement .error{
			color:${messageColors?.error};
		}

		${formElement} h2{
			color:${titleColor};
		}

		${formElement} p{
			color:${descColor};
		}

		${formElement} .noticeElement p {
			margin-top: 15px;
		}

		${formElement} input::placeholder {
			${getColorsCSS(fieldColors)};
		}

		${formElement} input[type="text"], ${formElement} input[type="email"]{
			${getColorsCSS(fieldColors)};
			padding:${getBoxValue(fieldPadding)};
			${getBorderCSS(fieldBorder)};
			margin:${getBoxValue(fieldMargin)};
			border: 1px solid #e9eaf1;
		}

		${formElement} .submitBtn button {
			${getColorsCSS(btnColors)};
			padding:${getBoxValue(btnPadding)};
			${getBorderCSS(btnBorder)};
		}

		${formElement} .submitBtn button:hover {
			${getColorsCSS(btnHovColors)};
			opacity:0.9;
		}

		#mcbMailChimp-${clientId} .form2 .form .submitBtn button {
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;
		}

		@media all and (max-width: 575px) {
			#mcbMailChimp-${clientId} .form2 .form .submitBtn button {
				${getBorderCSS(btnBorder)};
			}
		 }

		${formElement} .submitBtn .loader{
			${getBorderCSS(btnBorder)};
		}

		${formElement} .submitBtn .disable {
			opacity:0.7;
			pointer-events: none;
		}
		`.replace(/\s+/g, ' ')
	}} />;
}
export default Style;