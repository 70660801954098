
import { useEffect, useState } from 'react';
import Default from './Components/Forms/Default';
import FormOne from './Components/Forms/FormOne';
import FormTwo from './Components/Forms/FormTwo';
import FormThree from './Components/Forms/FormThree';

const Forms = ({ attributes, apiKey: key, children, accessToken }) => {

	const { elements, content, placeholder, theme, message, config } = attributes;
	const { apiKey, audienceId, endpoint_url } = config

	const [email, setEmail] = useState("");
	const [fName, setFName] = useState("");
	const [lName, setLName] = useState("");
	const [loading, setLoading] = useState();
	const [status, setStatus] = useState();
	const [showNotice, setShowNotice] = useState();
	const [localMessage, setLocalMessage] = useState();
	const [emailValid, setEmailValid] = useState();
	const [classAddDisable, setClassAddDisable] = useState(true);

	// Email Validation 
	const ValidateEmail = (input) => {
		var validRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
		if (input.match(validRegex)) {
			setEmailValid(true);
			setClassAddDisable(true);
		} else {
			setEmailValid(false);
			setClassAddDisable(false);
		}
	}

	// Fetch the data 
	const handleFormData = async () => {

		try {
			setLoading(true);
			const res = await fetch(`${mcbData?.ajaxUrl}?action=mcbSubmit_Form_Data&nonce=${mcbData?.nonce}&apiKey=${key || apiKey}&accessToken=${accessToken}&audienceId=${audienceId}&email=${email}&fName=${fName}&lName=${lName}&endpoint_url=${endpoint_url}`);
			setLoading(false);
			const response = await res.json();
			setStatus(response.status);
			setClassAddDisable(true);
			setShowNotice(true);

			setTimeout(() => {
				setShowNotice(false);
			}, 3000);

		} catch (error) {
			console.error('Error:', error);
		}
	}

	// Status Check And Define message
	useEffect(() => {
		if (status === "subscribed") {
			setLocalMessage(<p className='success'>{message?.success}</p>);
			setEmail("");
			setFName("");
			setLName("");
			setClassAddDisable(false);
			setEmailValid(true);
		} else if (status === 400) {
			setLocalMessage(<p className='error'>{message?.error}</p>);
		} else if (status === 404) {
			setLocalMessage(<p className='error'>Audience Id Not Found!</p>);
		} else if (status === 502) {
			setLocalMessage(<p className='error'>Invalid API Key!</p>);
		} else if (status === 510) {
			setLocalMessage(<p className='error'>Audience ID Required!</p>);
		} else {
			setLocalMessage(<p className='error'>Invalid API Key!</p>);
		}
	}, [status]);

	const globalProps = {
		child: children,
		classAddDisable,
		elements,
		email,
		placeholder,
		setEmail,
		ValidateEmail,
		content,
		handleFormData,
		emailValid,
		loading,
		showNotice,
		message: localMessage
	}

	return <div className={`mcbMailChimp ${theme}`}>

		{'default' === theme &&
			<Default {...globalProps} fName={fName} lName={lName} setFName={setFName} setLName={setLName} />}

		{'form1' === theme &&
			<FormOne {...globalProps} attributes={attributes} />}

		{'form2' === theme &&
			<FormTwo {...globalProps} attributes={attributes} />}

		{'form3' === theme &&
			<FormThree {...globalProps} fName={fName} lName={lName} setFName={setFName} setLName={setLName} />}
	</div>
}
export default Forms;